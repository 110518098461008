import extractParams from "../utils/extractParams";
import axiosConfig from "./axiosConfig";

const fetchUsersService = async (formData = {}) => {
	const params = extractParams(formData, "id", "query");
	const { data } = await axiosConfig.get("/users", { params });
	return data;
};

const updateUserService = async (formData = {}) => {
	const params = extractParams(formData, "name", "canHavePremiumName", "password");
	const { data } = await axiosConfig.patch("/users", params);
	return data;
};

const updateDomainService = async (formData = {}) => {
	const params = extractParams(formData, "domain");
	const { data } = await axiosConfig.patch("/users/dm", params);
	return data;
};

const updateFCMTokenService = async (token, authToken) => {
	const { data } = await axiosConfig.post(
		"/users/token",
		{ token },
		{
			headers: {
				Authorization: `${authToken}`,
			},
		}
	);
	return data;
};

const followService = async ({ id }) => {
	const { data } = await axiosConfig.post("/users/follow", { id });
	return data;
};
const updateDPService = async (formData = {}) => {
	try {
		const headers = { "Content-Type": "multipart/form-data" };
		const { data } = await axiosConfig.patch("/users/dp", formData, { headers });
		return data;
	} catch (error) {
		console.error("Error updating profile picture:", error);
		// Handle the error by either returning a fallback response or throwing it to the caller
		throw error; // rethrow the error for further handling by the caller
	}
};

// const updateDPService = async (formData = {}) => {
// 	const headers = { "Content-Type": "multipart/form-data" };
// 	const { data } = await axiosConfig.patch("/users/dp", formData, { headers });
// 	return data;
// };



const getSagaCountService = async (formData = {}) => {
	const headers = { "Content-Type": "multipart/form-data" };
	const params = extractParams(formData, "userId");
	const { data } = await axiosConfig.get("/users/getSagaCount", { params });
	return data;
};

export {
	fetchUsersService,
	updateUserService,
	updateDPService,
	updateDomainService,
	getSagaCountService,
	followService,
	updateFCMTokenService,
};
