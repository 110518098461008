import React, { useEffect, useState } from "react";
import { sendIcon, fileIcon } from "../../assets";
import { useDispatch, useSelector } from "react-redux";
import { addPost, updatePost } from "../../features/postSlice";
import useFetch from "../../hooks/useFetch";
import Compress from "compress.js";
import { closeIcon } from "../../assets/index";
import "./createnews.css";
import useUsersTags from '../../hooks/useUsersTags';
import { showModal } from '../../features/modalSlice';
import { addNews, setUserNews } from "../../features/newsSlice";
import { fetchNewsService } from "../../services/newsService";

const initialForm = { caption: "", youtubeLink: "", previews: [], images: [], imageIdForRemoving: [] };
const MAX_IMAGES_UPLOAD_AMOUNT = 3;

const CreateNews = ({ post, id, close }) => {
    const [form, setForm] = useState(initialForm);
    const [youtubeEmbed, setYoutubeEmbed] = useState("");
    const [previousYoutubeEmbed, setPreviousYoutubeEmbed] = useState("");
    const setFormCaption = (inputValue) => {
        setForm({ ...form, caption: inputValue });
    };
    const { handleUsersTags, inputRef, getSuggestionsList } = useUsersTags(setFormCaption);
    const allNews = useSelector(state => state.news.allPosts)
    console.log(allNews, 'allNew-sallNewsallNew--------')

    useEffect(() => {
        if (post && post._id) {
            setForm({
                caption: post.caption,
                youtubeLink: post.youtubeLink || "",
                previews: post.images ?? [],
                images: post.images ?? []
            });
        }
    }, [post]);

    const dispatch = useDispatch();
    const customFetch = useFetch();
    const compress = new Compress();

    const compressImage = async files => {
        const options = { size: 1, quality: 0.75, maxWidth: 1920, maxHeight: 1920, resize: true, rotate: false };
        const data = await compress.compress(files, options);
        return data;
    };

    const detectYoutubeLink = text => {
        const youtubeRegex = /^(https?:\/\/)?(www\.)?(youtube\.com\/watch\?v=|youtu\.be\/)([\w-]{11})[^ ]*/;
        const match = text.match(youtubeRegex);

        if (match) {
            const youtubeLink = match[0];
            const embedUrl = youtubeLink.replace("watch?v=", "embed/");
            // Only update youtubeLink and youtubeEmbed if it's a new link
            if (youtubeLink !== form.youtubeLink) {
                setYoutubeEmbed(embedUrl);
                setPreviousYoutubeEmbed(embedUrl);
                setForm(prevState => ({ ...prevState, youtubeLink }));
                if (form.previews.length) {
                    setForm(prevState => ({ ...prevState, images: [], previews: [] }));
                }
            }
        } else {
            setYoutubeEmbed("");
            setForm(prevState => ({ ...prevState, youtubeLink: "" }));
        }
    };

    const loadImage = async e => {
        const input = e.target;
        if (!input || input.files.length === 0) return;

        const files = Array.from(input.files).slice(0, MAX_IMAGES_UPLOAD_AMOUNT);

        if (input.files.length + form.previews.length > MAX_IMAGES_UPLOAD_AMOUNT) {
            dispatch(showModal({ msg: `You can upload maximum ${MAX_IMAGES_UPLOAD_AMOUNT} images` }));
            return;
        }

        const previews = await Promise.all(files.map(async (file) => {
            const reader = new FileReader();

            const readAsDataURL = (file) => new Promise((resolve, reject) => {
                reader.onload = e => resolve(e.target.result);
                reader.onerror = e => reject(e);
                reader.readAsDataURL(file);
            });

            const compressedData = await compressImage([file]);
            const image = Compress.convertBase64ToFile(compressedData[0]?.data, compressedData[0]?.ext);

            return {
                preview: { src: await readAsDataURL(file) },
                image
            };
        }));

        setForm(prevState => {
            return {
                ...prevState,
                previews: [...prevState.previews, ...previews.map(p => p.preview)],
                images: [...prevState.images ?? [], ...previews.map(p => p.image)],
            }
        });

        // If there's a youtubeLink and a new image is uploaded, remove the youtubeLink and image
        if (form.youtubeLink) {
            setYoutubeEmbed("");
            setPreviousYoutubeEmbed("");
            setForm(prevState => ({ ...prevState, youtubeLink: "" }));
        }
        setForm(prevState => ({ ...prevState, ...previews.map(p => p.image) }));
    };

    const removePreview = (index) => {
        const images = [...form.images ?? []];
        const previews = [...form.previews];
        const imageIdForRemove = previews[index].publicID;

        !!images.length && images.splice(index, 1);
        previews.splice(index, 1);
        setForm(prevState => {
            if (imageIdForRemove) {
                return {
                    ...prevState,
                    images,
                    previews,
                    imageIdForRemoving: [...prevState.imageIdForRemoving ?? [], imageIdForRemove]
                }
            } else {
                return { ...prevState, images, previews }
            }
        });
    }

    const showHint = () => {
        if (form.previews.length === MAX_IMAGES_UPLOAD_AMOUNT) {
            dispatch(showModal({ msg: `Remove uploaded image first, you can upload maximum ${MAX_IMAGES_UPLOAD_AMOUNT} images` }));
        }
    }

    const cancelYoutubeLinkPreview = () => {
        // Reset youtubeEmbed and youtubeLink
        setYoutubeEmbed("");
        setForm(prevState => ({ ...prevState, youtubeLink: "" }));
    };

    const submitHandler = async e => {
        e.preventDefault();
        const formData = new FormData();
        for (let i = 0; i < form.images?.length; i++) {
            formData.append('images', form.images[i]);
        }
        formData.append("caption", form.caption.trim());
        formData.append("youtubeLink", form.youtubeLink.trim());
        if (form.imageIdForRemoving?.length) {
            formData.append("imageIdForRemoving", form.imageIdForRemoving);
        }
        if (post?._id) {
            dispatch(updatePost({ customFetch, id: post._id, formData }));
            close();
        } else {
            const data = await dispatch(addNews({ customFetch, formData }));
            const data1 = await customFetch(fetchNewsService, { userId: id, });
            if (data1) dispatch(setUserNews(data1));
        }
        setYoutubeEmbed("");
        setForm(initialForm);
    };

    const handleInputChanges = (e) => {
        const inputValue = e.target.value;
        setForm({ ...form, caption: inputValue });
        // detectYoutubeLink(inputValue);
        handleUsersTags(inputValue);
    }
    const {

        search: { webTheme },
    } = useSelector((state) => state);
    return (
        <article className={webTheme ? "createpost_dark gradient-border" : "createpost_light gradient-border"}>
            <form onSubmit={submitHandler}>
                <textarea
                    placeholder="Write Latest News Here!"
                    value={form.caption}
                    onChange={handleInputChanges}
                    ref={inputRef}
                />

                {getSuggestionsList("below")}
                {youtubeEmbed && (
                    <div className="youtube-embed">
                        <iframe src={youtubeEmbed} frameBorder="0" allowFullScreen title="YouTube video"></iframe>
                        <div className="cross-icon" onClick={() => {
                            cancelYoutubeLinkPreview();
                            setYoutubeEmbed("")
                        }}>
                            <img src={closeIcon} alt="Remove" />
                        </div>
                    </div>
                )}

                {!!form.previews.length && (
                    <div className="uploaded-image">
                        {form.previews.map((preview, index) => {
                            return (
                                <div className="preview-item" key={index}>
                                    <img src={preview.src} alt="Uploaded file" />
                                    <div className="close-icon" onClick={() => { removePreview(index) }}>
                                        <img src={closeIcon} alt="Remove" />
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                )}

                <div className="btns">
                    <label htmlFor={id || "image"} aria-label="Select file">
                        <div onClick={showHint} style={{ backgroundColor: webTheme ? '#423f3f' : '#D0D0D0' }}>
                            <img src={fileIcon} alt="Select file" />
                        </div>
                    </label>
                    <input type="file" id={id || "image"}
                        accept="image/png, image/jpeg"
                        onChange={loadImage}
                        multiple
                        disabled={form.previews.length === MAX_IMAGES_UPLOAD_AMOUNT}
                    />
                    <button type="submit" aria-label="Submit" style={{ backgroundColor: webTheme ? '#423f3f' : '#D0D0D0' }}>
                        <img src={sendIcon} alt="Send" />
                    </button>
                </div>
            </form>
        </article>
    );
};

export default CreateNews;
