import axiosConfig from "./axiosConfig";

const doBanService = async (body) => {
  const { data } = await axiosConfig.post("/admin/ban", body);
  return data;
};

const verifyUserService = async (body) => {
  const { data } = await axiosConfig.post("/admin/verify", body);
  return data;
};

const deletePostService = async (body) => {
  const { data } = await axiosConfig.post("/admin/post-delete", body);
  return data;
};
const deleteNewsService = async (body) => {
  const { data } = await axiosConfig.post("/admin/news-delete", body);
  return data;
};

export {
  doBanService,
  verifyUserService,
  deletePostService,
  deleteNewsService
}
